<template>
  <portal to="dialog" :disabled="!$store.state.avalonPage.displayPageDialog">

    <!-- Template Preview Right Sidebar  -->
    <v-sheet v-if="selectedPreviewTemplate && generalNavigation === 'template'"
             key="sidebar"
             style="position: fixed; top : 40px; right: 64px; bottom: 40px; z-index : 99999999"
             class="d-flex flex-column align-center"
             width="300">

      <!-- Sidebar Header -->
      <v-sheet width="100%"  class="d-flex align-center justify-space-between pt-3">

        <h5 @click="selectedPreviewTemplate = null" class="mt-2 pointer" :style="`color : ${wsACCENT}`">
          <v-icon class="mr-2" :color="wsACCENT">mdi-arrow-u-left-top</v-icon>
          {{ $t('ReturnToTemplates') }}
        </h5>
        <v-btn  @click="$store.state.avalonPage.displayPageDialog = false" class="mr-n3" icon>
          <v-icon :color="wsDARKER" icon>mdi-close</v-icon>
        </v-btn>
      </v-sheet>

      <!-- Page Description  -->
      <v-sheet width="100%" color="transparent" class="d-flex align-center" height="100%">
        <div class="mt-n16">
          <div class="d-flex">

            <v-btn :disabled="!previousTemplate" @click="openPreviousTemplate" :style="`background-color: ${wsLIGHTCARD}`" icon class="mr-4">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn :disabled="!nextTemplate" @click="openNextTemplate" :style="`background-color: ${wsLIGHTCARD}`" icon >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
          <h3 :style="`color : ${wsDARKER}`" class="mt-6">{{ selectedPreviewTemplate.name }}</h3>
          <h5 class="font-weight-regular mt-1" :style="`color : ${wsACCENT}`">{{ selectedPreviewTemplate.description }}</h5>
          <div class="mt-4">
            <ws-button

                @click="savePage( selectedPreviewTemplate.id )"
                label="AddPage"
            />
            <ws-link v-if="selectedPreviewTemplate.category === 'saved' && false"  :to="businessPublicLink(`page/${selectedPreviewTemplate.id}/editor`)"  new-window>
              <ws-button class="ml-2" label="Edit" text />
            </ws-link>
          </div>


        </div>
      </v-sheet>

    </v-sheet>

    <!-- Page Editor Dialog  -->
    <v-sheet
        v-if="$store.state.avalonPage.displayPageDialog"
        :color="wsACCENT + 'bc' "
        style="position: fixed; top: 0; bottom: 0; left: 0;right: 0; z-index: 9999999 ;"
        class="d-flex align-center justify-center pa-10"
    >
      <v-sheet :width="width" class="d-flex fill-height overflow-hidden" style="border-radius: 8px; transition: width 0.2s ease">

        <!-- SIDEBAR-->
        <v-sheet :color="wsBACKGROUND" min-width="300" width="300" class="fill-height py-3">
          <h2 style="font-size: 19px" :style="`color : ${wsDARKER}`" class="mb-4 mt-2 px-6"> {{ dialogTitle }}</h2>
          <ws-avalon-navigation-list
              v-model="navigation"
              @input="handleNavigation($event)"
              :items="pageNavigationItems"
              mandatory
              no-edit
          />
        </v-sheet>
        <!-- CONTENT-->
        <div class="justify-space-between overflow-hidden d-flex fill-height flex-column" style="width: 100%">
          <!-- Header -->
          <v-sheet color="transparent" v-if="!selectedPreviewTemplate" class="d-flex justify-end align-center pr-3  pt-3" >
            <v-btn :color="wsDARKER" @click="$store.state.avalonPage.displayPageDialog = false" icon>
              <v-icon :color="wsDARKER" >
                mdi-close
              </v-icon>
            </v-btn>
          </v-sheet>
          <!-- CONTENT-->
          <v-sheet  style="height: 100%;border-radius: 8px;" class="px-6 overflow-y-auto ">
            <!-- CONTENT -->

            <!-- General -->
            <div v-if="generalNavigation === 'general'" >
              <ws-text-field
                  v-if="!selectedCourse"
                  v-model="entityData.title"
                  :label="$t('avalon.page.editor.name.title')"
                  :placeholder="$t('EnterName')"
                  :error="titleError"
                  @input="titleError = false"

              >
              </ws-text-field>
              <h5 v-if="!selectedCourse" class="font-weight-medium mt-2" :style="`color: ${wsDARKLIGHT}`">{{ $t('avalon.page.editor.name.text') }}</h5>
              <ws-text-field
                  class="mt-4"
                  v-model="entityData.alias"
                  :label="$t('avalon.page.editor.link.title')"
                  :error="aliasError"
                  @input="aliasError = false"
                  :readonly="hasCoursePages"
                  description="avalon.page.editor.link.text"
              >

                <template #prepend-inner>
                  <span :style="`color : ${wsDARKLIGHT}`" class="mt-1"> {{ pageRoot }}</span>
                </template>

              </ws-text-field>

              <ws-text-field
                  v-if="!(entityData.course_id || selectedCourse)"
                  class="mt-4"
                  v-model="entityData.name"
                  :label="$t('avalon.page.editor.shortName.title')"
                  :placeholder="$t('EnterName')"
                  :error="nameError"
                  @input="nameError = false"
                  description="avalon.page.editor.shortName.text"
              />


              <ws-select
                  v-if="!(entityData.course_id || selectedCourse)"
                  v-model="entityData.folder"
                  :items="$store.state.avalon.folders"
                  :label="$t('avalon.page.editor.folder.title')"
                  :placeholder="$t('avalon.page.editor.folder.text')"
                  class="mt-4"
                  autocomplete
                  allow-new-data
                  new-data-text="AddFolder"
                  clearable
              />

              <div class="pt-3">
                <ws-switch
                    class="mb-n3"
                    v-if="!(entityData.course_id || selectedCourse)"
                    v-model="entityData.homepage"
                    :placeholder="$t('avalon.page.editor.homepage.title') "
                    :tooltip="$t('avalon.page.editor.homepage.text')"
                />
                <ws-switch
                    class="mb-n3"
                    v-if="!newPage && !copyPage"
                    @input="entityData.active = !$event"
                    :value="!entityData.active"
                    :placeholder="$t('UnPublish') "
                />
                <ws-switch
                    class="mb-n3"
                    v-model="entityData.is_template"
                    :placeholder="$t('avalon.page.editor.is_template.title') "
                    :tooltip="$t('avalon.page.editor.is_template.text')"
                />
              </div>

            </div>
            <!-- Seo -->
            <div v-if="generalNavigation === 'seo'" >

              <h5 :style="`color : ${wsACCENT}`" style="font-weight: 400" class="pt-0">
                {{ $t('avalon.page.editor.seo.description') }}
              </h5>

              <v-sheet class="mt-5 pa-6 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`">

                <image-param-picker
                    v-model="entityData.meta_image"
                    no-border
                    height="240"
                >

                  <h5 :style="`color : ${wsDARKLIGHT}`" style="max-width: 400px" class="mt-4 mx-auto" >
                    {{ $t('avalon.page.editor.seo.image_title')  }}
                  </h5>
                  <h5 :style="`color : ${wsDARKLIGHT}`" style="max-width: 400px" class="font-weight-regular mx-auto mt-4" >
                    {{ $t('avalon.page.editor.seo.image_text')  }}
                  </h5>

                </image-param-picker>

                <h4 class="mt-4" style="font-size: 16px; font-weight: 400; color : #1782D7"  >
                  <span v-if="!entityData.meta_title">
                    {{ entityData.title }} | {{ $store.state.business.selectedBusiness.name }}
                  </span>
                  <span v-else>
                    {{ entityData.meta_title }}
                  </span>
                </h4>
                <h5 class="mt-2" style="opacity: 0.8; color : #9E9E9E; font-size: 12px" > {{ pageRoot }}{{ entityData.alias }}</h5>
                <v-divider class="my-2" :style="`border-color : ${wsBACKGROUND}`"/>
                <h5 :style="`color : ${wsDARKER}`" style="opacity: 0.9;" class="mt-4" > {{ entityData.meta_description || $t('avalon.page.editor.seo.page_description_text') }} </h5>

              </v-sheet>

              <ws-accordion
                  :items="seoAccordionItems"
                  class="mt-6"
              >
                <template #item.edit>
                  <div class="pa-6">
                    <ws-text-field
                        v-model="entityData.meta_title"
                        :placeholder="$t('EnterPageTitle')"
                        :label="$t('Title')"
                    />
                    <ws-text-field
                        class="mt-5"
                        v-model="entityData.meta_description"
                        :placeholder="$t('EnterDescription')"
                        :label="$t('Description')"
                        area
                    />
                  </div>

                </template>

              </ws-accordion>


            </div>
            <!-- Templates -->
            <div v-if="generalNavigation === 'template'" style=" width: 100%" class="fill-height">

              <!-- Templates Selector -->
              <v-row v-if="!selectedPreviewTemplate"  class="fill-height py-2">
                <v-col cols="4"   v-for="(page,i) in templatesFiltered" :key="'template'+i" style="position : relative; max-height : 486px">
                  <!-- Actions -->

                  <!-- Template Small Preview -->
                  <v-hover #default="{hover}">
                    <v-sheet  :style="`outline :  2px solid  ${!hover ? wsBACKGROUND : wsACCENT}`" max-height="462"   class="overflow-hidden fill-height">

                      <v-sheet height="500">
                        <preview-page
                            v-if="!page.blank"
                            :page="page.id"
                            :width="((WINDOW_WIDTH-300-176)/3) + 1"
                            auto-scroll
                        />
                        <div v-else class="d-flex justify-center align-center fill-height">
                          <v-icon style="margin-bottom : 100px" size="100" :color="wsBACKGROUND">mdi-assistant</v-icon>
                        </div>
                      </v-sheet>

                      <v-sheet
                          :color="wsLIGHTCARD"
                          :style="`border-top : 1px solid ${wsBACKGROUND}`"
                          class="pa-3"
                          style="position: absolute;  bottom: 12px; left: 12px; right: 12px; z-index: 999;"
                      >
                        <h4 class="shorten-text">{{  !page.blank ? page.title : $t('BlankPage')  }}</h4>
                        <h5 style="height: 38px" class="font-weight-regular mt-2 shorten-text-two" :style="`color : ${wsACCENT}`">
                          {{ !page.blank  ? page.description : $t('CreateBlankPage') }}
                        </h5>
                        <div class="d-flex align-center">
                          <ws-button
                              @click="savePage(!page.blank ? page.id : null)"
                              label="Select"
                              :loading="LOADING"
                              :disabled="LOADING"
                              class="mt-3"
                              outlined
                          />
                          <ws-button
                              v-if="!page.blank"
                              @click="previewTemplate(page , i)"
                              label="Preview"
                              class="mt-3"
                              text
                          />
                        </div>

                      </v-sheet>

                    </v-sheet>
                  </v-hover>


                </v-col>
              </v-row>
              <!-- Template Preview -->

              <!-- Template  -->
              <v-sheet class="py-6" color="transparent" min-height="100%" style="padding-right: 324px" v-if="selectedPreviewTemplate" >

                <preview-page
                    key="template_preview"
                    :page="selectedPreviewTemplate.id"
                    :width="((WINDOW_WIDTH-300-176-250-24)) + 1"
                    cursor="move"
                />

              </v-sheet>

            </div>

          </v-sheet>

          <!-- Action buttons-->
          <v-sheet v-if="generalNavigation !== 'template' " class="pt-3 pb-6 px-6 d-flex justify-end" >

            <div class="d-flex align-center">
              <ws-button
                  @click="$store.state.avalonPage.displayPageDialog = false"
                  class="mr-2"
                  label="Cancel"
                  outlined
              />
              <ws-button
                  @click="saveButtonAction"
                  :label="saveButtonText"
              />
            </div>

          </v-sheet>
        </div>
      </v-sheet>
    </v-sheet>

  </portal>
</template>

<script>

import { mapActions , mapState } from "vuex";
import languagesList from "@/mixins/languagesList";
import wsAvalonNavigationList from "@/components/AvalonEditor/UI/sidebar/UI/wsAvalonNavigationList";
import previewPage from "@/components/AvalonEditor/Templates/previewPage";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";
export default {
  name: "avalonNewPageDialog",
  mixins : [languagesList],
  components : {
    imageParamPicker,
    wsAvalonNavigationList,
    previewPage
  },
  data() {
    return {
      templates : [],
      expandTemplates : false,
      categories : [],
      selectedCategory : null,
      entityData : {},
      navigation : 'general',
      generalNavigation : 'general',
      aliasError : false,
      titleError : false,
      nameError  : false,
      langError  : false,
      aliasBuffer: '',
      selectedPreviewTemplate : null,
      selectedPreviewtemplateIndex : null
    }
  },
  computed : {
    ...mapState('avalonPage',['newPage' , 'copyPage' , 'selectedCourse' , 'course' , 'selectedPage']),
    ...mapState('avalon',['selectedLang' , 'coursesSelect' , 'homepage']),


    nextTemplate() {
      if (this.selectedPreviewtemplateIndex  > ( this.templatesFiltered.length - 1) ) {
        return null
      }
      return this.templatesFiltered[this.selectedPreviewtemplateIndex+1]
    },
    previousTemplate() {
     if (this.selectedPreviewtemplateIndex === 0 ) {
       return null
     }
     if ( this.templatesFiltered[this.selectedPreviewtemplateIndex-1].blank ) {
       return null
     }
     return this.templatesFiltered[this.selectedPreviewtemplateIndex-1]
    },

    seoAccordionItems() {
      return [
        { name : this.$t('AdditionalSettings') , value : 'edit' }
      ]
    },
    saveButtonText() {
      if ( this.newPage ) {
        return this.$t('Next')
      }
      return this.$t('Save')
    },

    templatesFiltered() {
      let items = this.templates
      if ( this.selectedCategory && this.selectedCategory !== 'all' ) {
        items = items.filter(el => el.category && el.category.toString() === this.selectedCategory.toString())
      }
      return items
    },

    width() {
      return this.generalNavigation === 'template' ? '100%' : '900'
    },
    pageNavigationItems() {
      let items =  [
        {
          text: this.$t('General'),
          value : 'general',
          edit : false,
          noEdit : true
        },
        {
          text: 'SEO',
          value : 'seo',
          edit : false,
        }
      ]
      if ( this.newPage ) {
        let categories = [ {
          text: this.$t('UserTemplates'),
          value : 'saved',
          no_edit : true,
        }]
        this.categories.forEach(item => {
          categories.push(
              {
                text: item.text,
                value : item.value,
                no_edit : true,
              }
          )
        })
        items.push({
          text: this.$t('Templates'),
          value : 'template',
          edit : false,
          expanded : this.expandTemplates,
          disable_expand : true,
          children : categories
        })
      }


      return items
    },
    navigationSelect() {
      return [
        { text : this.$t('General') , value : "general" },
        { text : 'SEO' , value : "seo" }
      ]
    },

    dialogTitle() {

      if ( this.newPage ) {
        return this.$t('avalon.page.editor.add')
      }
      if ( this.copyPage ) {
        return this.$t('avalon.page.editor.copy')
      }
      return this.$t('avalon.page.editor.edit')
    },

    pageRoot() {
      let lang = this.AVALON_LANG_PRIMARY === this.selectedLang ? '' : (this.selectedLang + '/')
      return 'www.westudy.ua/'
          + lang
          + this.$store.state.business.selectedBusiness.alias
          + '/page/'
    },

    navigationItems() {
      return [
        { text : this.$t('avalon.page.editor.navigation.general') , value : 'general' },
        { text : this.$t('avalon.page.editor.navigation.template') , value : 'template' }
      ]
    },

    navigationCourseItem() {
      if ( !this.selectedCourse ) {
        return null
      }
      let item = this.coursesSelect.find(el=> el.value === this.selectedCourse)
      if ( !item ) {
        return null
      }
      return item
    },
    coursePages() {
      let pages = []
      if (!this.navigationCourseItem) {
        return pages
      }

      Object.keys(this.navigationCourseItem.page).forEach(lang=>{
        if ( this.navigationCourseItem.page[lang] ) {
          pages.push({ lang : lang , alias : this.navigationCourseItem.page_alias })
        }
      })

      return pages
    },
    hasCoursePages() {
      return this.coursePages.length > 0
    }

  },
  methods : {
    ...mapActions('avalonPage',[
        'ADD_PAGE' ,
        'EDIT_PAGE' ,
        'GET_PAGE_EDIT',
        'CHECK_ALIAS',
    ]),
    ...mapActions('avalon',[
      'GET_TEMPLATE_PAGES',
    ]),

    openNextTemplate() {
      this.selectedPreviewTemplate = this.nextTemplate
      this.selectedPreviewtemplateIndex += 1
    },
    openPreviousTemplate() {
      this.selectedPreviewTemplate = this.previousTemplate
      this.selectedPreviewtemplateIndex -= 1
    },

    previewTemplate(page , index) {
      this.selectedPreviewTemplate = page
      this.selectedPreviewtemplateIndex = index
    },
    async saveButtonAction() {
      if ( this.newPage ) {
        if ( this.generalNavigation === 'general' ) {
          if ( !await this.validateGeneralInfo() ) {
            this.generalNavigation = 'general'
            return
          }
          this.navigation = 'seo'
          this.generalNavigation = 'seo'
        }
        else if ( this.generalNavigation === 'seo' ) {
          this.expandTemplates = true
          this.navigation = 'template'
          this.generalNavigation = 'template'
          this.selectedCategory = 'all'
        }
      } else {
        this.savePage()
      }
    },

    async validateGeneralInfo() {

      if ( !this.entityData.alias ) {
        this.aliasError = true
        this.notify(this.$t('avalon.error.page.enter_alias') , 'warning')
        return false
      }

      if ( !this.entityData.alias.match("^[a-zA-Z0-9_-]*$")) {
        this.aliasError = this.$t('AliasRegexError')
        this.notify(this.$t('AliasRegexError') , 'warning')
        return false
      }

      if ( this.entityData.alias.length < 3) {
        this.aliasError = this.$t('AliasLengthError')
        this.notify(this.$t('AliasLengthError') , 'warning')
        return false
      }

      if ( !(this.selectedCourse || this.entityData.course_id)  ) {
        if ( !this.entityData.title ) {
          this.titleError = true
          this.notify(this.$t('avalon.error.page.enter_title') , 'warning')
          return false
        }

        if ( !this.entityData.name ) {
          this.nameError = true
          this.notify(this.$t('avalon.error.page.enter_name'), 'warning')
          return false
        }

      }

      if ( this.selectedPage  ) {
        if ( this.entityData.alias !== this.aliasBuffer ) {
          if ( !await this.CHECK_ALIAS({alias : this.entityData.alias , lang : this.selectedLang}) ) {
            this.aliasError = true
            this.notify(this.$t('avalon.error.page.alias_exists') , 'warning')
            return false
          }
        }

      } else {
        if ( !await this.CHECK_ALIAS({alias : this.entityData.alias , lang : this.selectedLang}) ) {
          this.aliasError = true
          this.notify(this.$t('avalon.error.page.alias_exists') , 'warning')
          return false
        }
      }


      return true

    },
    async handleNavigation(value) {
      this.selectedPreviewTemplate = null
      if ( this.generalNavigation === 'general' && !await this.validateGeneralInfo() ) {
        this.generalNavigation = 'general'
        this.navigation = 'general'
        return
      }

      if ( ['general' , 'seo' , 'template' ].includes(value) ) {
        this.generalNavigation = value
        this.selectedCategory =  value === 'template' ? 'all' : value
        this.expandTemplates = value === 'template'
      } else {
        this.expandTemplates = true
        this.selectedCategory =  value === 'template' ? 'all' : value
      }
    },
    async savePage(templateId = null) {

      if ( templateId ) {
        this.entityData.id = templateId
      }

      if ( this.copyPage ) {
        this.entityData.id = this.selectedPage
      }

      if ( !await this.validateGeneralInfo() ) {
        return
      }

      if ( this.navigation === "general" && !this.selectedPage && !this.copyPage) {
        this.navigation = 'template'
        return
      }

      let result = !this.selectedPage || this.copyPage ? await this.ADD_PAGE(this.entityData) : await this.EDIT_PAGE(this.entityData)

      if ( !result ) {
        return
      }



      if (this.newPage || this.copyPage) {
        this.$store.state.avalon.selectedPage = result.id
        this.$store.state.avalonPage.displayPageDialog = false
        this.$router.push(this.businessPublicLink(`page/${result.id}/editor`) )
      }
      else {
        let index = this.$store.state.avalon.pagesSelect.findIndex( el=> el.value === result.id)
        if ( index === -1 ) {
          this.$store.state.avalonPage.displayPageDialog = false
          return
        }
        this.$store.state.avalon.pagesSelect[index].text = result.name
        this.$store.state.avalon.pagesSelect[index].homepage = result.homepage

        let aliasIndexes = this.$store.state.avalon.pagesSelect
            .map((el,index) => ({alias: el.alias, index}))
            .filter( el=> el.alias === this.$store.state.avalon.pagesSelect[index].alias )
            .map(el => el.index )
       aliasIndexes.forEach(aliasIndex => {
          this.$store.state.avalon.pagesSelect[aliasIndex].folder = result.folder
          this.$store.state.avalon.pagesSelect[aliasIndex].alias = result.alias
        })

        if ( result.homepage ) {
          this.$store.state.avalon.pagesSelect.forEach((el,index) => {
            this.$store.state.avalon.pagesSelect[index].homepage =  el.alias === result.alias
          })
        } else if ( this.homepage[this.entityData.lang] === result.id) {
          this.$store.state.avalon.pagesSelect.forEach((el,index) => {
            this.$store.state.avalon.pagesSelect[index].homepage =  false
          })
          this.COPY(this.$store.state.avalon.pagesSelect)
        }

      }

      this.$store.state.avalonPage.displayPageDialog = false

      this.checkNewFolder(result.folder , result.folder_name)

    },
    checkNewFolder(id , name ) {
      if ( !id ) {
        return
      }
      let index = this.$store.state.avalon.folders.findIndex(el => el.value === id )
      if ( index === -1 ) {
        this.$store.state.avalon.folders.push({
          text : name ,
          value : id
        })
      }
    },
    async getPage() {
      if ( !this.selectedPage || this.copyPage ) {
        return
      }
      let result  = await this.GET_PAGE_EDIT({ id : this.selectedPage , lang : this.selectedLang })
      if ( !result ) {
        return
      }
      this.entityData = result.page
      this.aliasBuffer = result.page.alias
    }
  },
  async mounted() {

    this.entityData = {}
    this.entityData.lang = this.$store.state.avalon.selectedLang
    await this.getPage()

    if ( this.selectedCourse ) {
      this.entityData.course = this.selectedCourse
    }
    if ( this.hasCoursePages ) {
      this.entityData.alias = this.coursePages[0].alias
    }

    if ( this.newPage ) {
      let result = await this.GET_TEMPLATE_PAGES(this.selectedLang)
      if ( !result || result === true ) {
        return
      }
      this.categories = result.categories
      this.templates = result.templates
      this.templates.unshift({
        blank : true
      })
      this.selectedCategory = result.categories[0].value
    }

  },
  beforeDestroy() {
    this.$store.state.avalonPage.selectedCourse = null
  }
}
</script>

<style scoped>

</style>