<template>

  <v-hover v-model="isHover" >

    <v-sheet
        :style="generalStyle"
        :width="width"
        :height="height"
        style="position: relative"
        class="overflow-hidden"
        :key="page"
    >
      <v-sheet
          width="1200"
          class="overflow-hidden overflow-hidden"
          style="transform-origin: top left;"
          :style="`transform : scale(${width/1200});`"
          ref="block"
      >
        <view-page
            @ready="pageReady"
            :style="`opacity:${ !ready ? 0 : 1 }; cursor: ${autoScroll ? 'zoom-in' : cursor}`"
            style="transition: all 0.3s ease;"

            preview
            :page="page"
            template
        />
      </v-sheet>

    </v-sheet>
  </v-hover>

</template>

<script>
import viewPage from "@/components/AvalonEditor/View/viewPage";
export default {
  name: "previewBlock",
  components : {
    viewPage
  },
  props : {
    page : {
    },
    width : {
      type : Number,
      default : 600
    },
    isSelected : {
      type : Boolean,
      default : false
    },
    autoScroll : {
      type : Boolean,
      default : false
    },
    cursor : {
      type : String,
      default : 'pointer'
    },
    parentHeight : {
      default : 300
    }
  },
  data() {
    return {
      height : 300,
      ready : true,
      isHover : false
    }
  },
  computed : {
    generalStyle() {
      let style = ''
      if ( this.autoScroll && this.isHover ) {
        style += `margin-top : -${this.height - this.parentHeight}px;`
        style += `transition : margin-top 3s ease `
      } else {
        style += `transition : margin-top 0.9s ease `
      }



      return style
    }
  },
  watch : {
    WINDOW_WIDTH() {
    },
  },
  methods : {
    getHeight() {
      let block = this.$refs.block
      this.height = block.$el.clientHeight * (this.width / 1200)
    },
    pageReady() {
      this.ready = true
      setTimeout(() => {
        this.getHeight()

      }, 100)
      setTimeout(() => {
        this.getHeight()

      }, 500)
    }
  },
  mounted() {

  }


}
</script>

<style scoped>

</style>